import { StateHandler } from '@/components/state-handler/StateHandler';
import { useGetObjectiveSetting } from '@/hooks/objective-setting/ObjectiveSetting.hook';
import { Box, Button, Divider, Paper, Stack, StackProps, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectiveCreateMutation, ObjectiveUpdateMutation } from '@/domain/objective/Objective.model';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';
import {
    createEmployeeReviewAction,
    deleteEmployeeReviewAction,
    updateEmployeeReviewAction,
} from '@/domain/employee-review-action/EmployeeReviewAction.service';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { EmployeeReviewAction } from '@/domain/employee-review-action/EmployeeReviewAction.model';
import { AddIndividualObjectiveDialog, IndividualObjectiveDialogFormType } from '@/page/objective/add-individual-objective-dialog/AddIndividualObjectiveDialog';
import { Target01Icon as ObjectivesIcon } from 'hugeicons-react';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { getAppConfig } from '@/config/config';
import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { formatDate } from 'date-fns';
import i18next from 'i18next';
import { getLabelTranslation } from '@/utils/language.util';
import { RichTextTypography } from '@/components/rich-text-typography/RichTextTypography';
import { FeedbackObjectivesActionFormType } from '@/page/review/employee-review-feedback-form/FeedbackFormPage.schema';

type FeedbackObjectiveActionsProps = {
    employeeId: number;
    disabled: boolean;
    refetchActions: () => void;
    employeeReview: EmployeeReview;
    actions: EmployeeReviewAction[];
    feedbackObjectiveActionsForm: FeedbackObjectivesActionFormType;
} & StackProps;

type ObjectiveActionDialogState = {
    open: boolean;
    activeObjectiveAction?: EmployeeReviewAction;
};

export const FeedbackObjectiveActions: FC<FeedbackObjectiveActionsProps> = ({
    employeeId,
    disabled,
    refetchActions,
    actions,
    employeeReview,
    feedbackObjectiveActionsForm,
}) => {
    const { t } = useTranslation();
    const [objectiveActionDialogState, setObjectiveActionDialogState] = useState<ObjectiveActionDialogState>({
        open: false,
        activeObjectiveAction: undefined,
    });

    const {
        data: objectiveSetting,
        isLoading: isLoadingObjectiveSetting,
        isError: isErrorObjectiveSetting,
        error: errorObjectiveSetting,
    } = useGetObjectiveSetting();

    const emptyState = (
        <Stack p={2}>
            <Typography variant={'body1'}>{t('reviews.write_feedback.objective_actions_empty_state')}</Typography>
        </Stack>
    );

    const resetObjectiveActionDialogState = () => {
        setObjectiveActionDialogState({
            open: false,
            activeObjectiveAction: undefined,
        });
    };
    const onSave = async (formValues: IndividualObjectiveDialogFormType, employeeReviewId: number, objectiveId?: number) => {
        if (objectiveId) {
            const objectiveMutation = {
                ...formValues,
                assigneeId: formValues.assigneeIds[0],
                parentId: formValues.parentId ?? undefined,
            };
            await onUpdateObjectiveAction(objectiveMutation, objectiveId);
        } else {
            const objectiveMutation = {
                ...formValues,
                parentId: formValues.parentId ?? undefined,
            };
            await onCreateObjectiveAction(objectiveMutation, employeeReviewId);
        }
    };

    const onCreateObjectiveAction = async (objectiveRequest: ObjectiveCreateMutation, employeeReviewId: number) => {
        const createActionMutation = {
            objectiveCreateMutation: objectiveRequest,
        };
        try {
            await createEmployeeReviewAction(employeeReviewId, createActionMutation);
            showSnackbar(t('reviews.write_feedback.objective_action_created'), 'success');
            refetchActions();
            resetObjectiveActionDialogState();
        } catch (error) {
            handleError(error);
        }
    };

    const onUpdateObjectiveAction = async (objectiveRequest: ObjectiveUpdateMutation, employeeReviewActionId: number) => {
        const updatedAction = {
            objectiveUpdateMutation: objectiveRequest,
        };

        try {
            await updateEmployeeReviewAction(employeeReviewActionId, updatedAction);
            showSnackbar(t('reviews.write_feedback.objective_action_updated'), 'success');
            refetchActions();
            resetObjectiveActionDialogState();
        } catch (error) {
            handleError(error);
        }
    };

    const onDeleteObjective = async (employeeReviewActionId: number) => {
        try {
            await deleteEmployeeReviewAction(employeeReviewActionId);
            showSnackbar(t('reviews.write_feedback.objective_action_deleted'), 'success');
            refetchActions();
            resetObjectiveActionDialogState();
        } catch (error) {
            handleError(error);
        }
    };

    if (!objectiveSetting) {
        return <></>;
    }

    return (
        <Stack gap={2} component={Paper} p={2}>
            <StateHandler
                isLoading={isLoadingObjectiveSetting}
                isError={isErrorObjectiveSetting}
                error={errorObjectiveSetting}
                emptyStateComponent={emptyState}
            >
                <Stack gap={1}>
                    <Typography variant='h2'>{t('reviews.write_feedback.create_objective_action_title')}</Typography>

                    {feedbackObjectiveActionsForm.instruction && (
                        <RichTextTypography variant='body2'>{getLabelTranslation(feedbackObjectiveActionsForm.instruction)}</RichTextTypography>
                    )}

                    {!!actions.length && (
                        <Box display={'grid'} gridTemplateColumns={'70% 1fr 1fr 5%'} gridTemplateRows={'auto'} rowGap={2} p={1}>
                            <Box display={'contents'}>
                                <Typography variant='body1bold'>{t('reviews.write_feedback.create_objective_name_header_title')}</Typography>
                                <Typography variant='body1bold' justifySelf={'center'}>
                                    {t('reviews.write_feedback.create_objective_action_create_by_header_title')}
                                </Typography>
                                <Typography variant='body1bold' justifySelf={'center'}>
                                    {t('reviews.write_feedback.create_objective_action_due_date_header_title')}
                                </Typography>
                                {!disabled && <Box />}
                            </Box>
                            <Divider sx={{ gridColumn: 'span 4' }} />
                            {actions.map(objectiveAction => (
                                <EmployeeReviewFeedbackObjectivesActionItem
                                    key={objectiveAction.id}
                                    objectiveAction={objectiveAction}
                                    disabled={disabled}
                                    onEdit={() => {
                                        setObjectiveActionDialogState({
                                            open: true,
                                            activeObjectiveAction: objectiveAction,
                                        });
                                    }}
                                    onDelete={() => {
                                        onDeleteObjective(objectiveAction.id);
                                    }}
                                />
                            ))}
                        </Box>
                    )}

                    <Button
                        fullWidth
                        onClick={() =>
                            setObjectiveActionDialogState({
                                open: true,
                                activeObjectiveAction: undefined,
                            })
                        }
                        disabled={disabled}
                    >
                        {t('reviews.write_feedback.create_objective_action_button')}
                    </Button>
                </Stack>
            </StateHandler>

            {objectiveActionDialogState.open && (
                <AddIndividualObjectiveDialog
                    open={true}
                    activeObjective={objectiveActionDialogState.activeObjectiveAction?.objective}
                    disabledEmployeeSelection={true}
                    onSave={formValues => {
                        onSave(formValues, employeeReview.id, objectiveActionDialogState.activeObjectiveAction?.id);
                    }}
                    employeeId={employeeId}
                    parentObjectiveEnabled={objectiveSetting?.parentObjectiveEnabled ?? false}
                    onClose={() => {
                        resetObjectiveActionDialogState();
                    }}
                />
            )}
        </Stack>
    );
};

type EmployeeReviewFeedbackObjectivesActionItemProps = {
    objectiveAction: EmployeeReviewAction;
    disabled: boolean;
    onEdit: () => void;
    onDelete: () => void;
};
const EmployeeReviewFeedbackObjectivesActionItem: FC<EmployeeReviewFeedbackObjectivesActionItemProps> = ({ objectiveAction, disabled, onEdit, onDelete }) => {
    const items = getMenuItems(onEdit, onDelete);
    const objective = objectiveAction.objective;
    const theme = useTheme();

    return (
        <Box
            display={'contents'}
            onClick={disabled ? undefined : onEdit}
            sx={{
                '& > *': {
                    backgroundColor: theme.palette.grey[50],
                    cursor: disabled ? 'default' : 'pointer',
                },

                // When any child is hovered
                '&:hover > *': {
                    backgroundColor: theme.palette.grey[200],
                },

                '& > :first-child': {
                    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`, // top left and bottom left corners rounded
                },
                '& > :last-child': {
                    borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`, // top right and bottom right corners rounded
                },
            }}
        >
            <Stack direction={'row'} gap={1} p={1} alignContent={'flex-start'}>
                <Box>
                    <ObjectivesIcon />
                </Box>

                <Stack direction={'column'}>
                    <Typography variant='h3'>{objective?.name}</Typography>
                    <Typography variant='body1bold'>{getLabelTranslation(objective?.category?.name)}</Typography>
                    <Typography variant='body2'>{objective?.description}</Typography>
                </Stack>
            </Stack>

            <Box p={1} justifyItems={'center'} alignContent={'center'}>
                <EmployeeAvatar employeeAvatar={objective.createdBy} />
            </Box>

            <Typography variant='body2' py={1} textAlign={'center'} alignContent={'center'}>
                {formatDate(objective?.dueDate, getAppConfig().DEFAULT_DATE_FORMAT)}
            </Typography>

            <Box alignContent={'center'}>{!disabled && <BasicMenu items={items} />}</Box>
        </Box>
    );
};

const getMenuItems = (onEdit: () => void, onDelete: () => void): BasicMenuItem[] => {
    const menuItems: BasicMenuItem[] = [];

    menuItems.push({
        title: i18next.t('general.edit'),
        onClick: onEdit,
    });
    menuItems.push({
        title: i18next.t('general.delete'),
        onClick: onDelete,
    });

    return menuItems;
};
