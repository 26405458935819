import { Employee } from '@/domain/employee/Employee.model';
import { MONTHS } from '@/utils/datetime.util';

import { EmployeePayrollLock } from '@/domain/employee-payroll-lock/EmployeePayrollLock.model';
import { DayPeriod, UnitType } from '../date/Date.model';
import { TimesheetSetting } from '../timesheet-setting/TimesheetSetting.model';
import { Area } from '@/domain/area/Area.model';
import { NonWorkingType, WorkingTimeRule } from '@/domain/shift/Shift.model';

export type LeaveTimesheet = {
    id: number;
    leavePercentage: number;
    leaveTypeId: number;
    startTimePeriod: DayPeriod;
    endTimePeriod: DayPeriod;
    startDate: LocalDate;
    endDate: LocalDate;
    unitType: UnitType;
};

export type TimesheetWarning = {
    rule: WorkingTimeRule;
    limit: number;
};

export enum TimesheetCreationMethod {
    FRONTEND_TIMESHEET = 'FRONTEND_TIMESHEET',
    FRONTEND_CLOCK_IN = 'FRONTEND_CLOCK_IN',
    MANUAL_CLOCK_IN = 'MANUAL_CLOCK_IN',
    TIMEMOTO_INTEGRATION = 'TIMEMOTO_INTEGRATION',
    IMPORT_EXCEL = 'IMPORT_EXCEL',
    SUPPORT = 'SUPPORT',
    PUBLIC_API = 'PUBLIC_API',
}

export enum TimesheetsRequestStatus {
    APPROVED = 'APPROVED',
    PENDING = 'PENDING',
    DECLINED = 'DECLINED',
    CANCELLED = 'CANCELLED',
}

export enum TimesheetType {
    TIMESHEET = 'TIMESHEET',
    TIMESHEET_PAYMENT = 'TIMESHEET_PAYMENT',
    SHIFT_TIMESHEET = 'SHIFT_TIMESHEET',
    LEAVE = 'LEAVE',
    FUTURE_COMPENSATION = 'FUTURE_COMPENSATION',
    FUTURE_LEAVE = 'FUTURE_LEAVE',
    FUTURE_UNPAID_LEAVE = 'FUTURE_UNPAID_LEAVE',
    MISSING = 'MISSING',
    MISSING_UNPAID = 'MISSING_UNPAID',
    MISSING_AND_UNPAID = 'MISSING_AND_UNPAID',
    AUTOFILL = 'AUTOFILL',
    FUTURE_MISSING = 'FUTURE_MISSING',
    NON_WORKING_DAY = 'NON_WORKING_DAY',
    UNPAID_LEAVE = 'UNPAID_LEAVE',
    COMPENSATION = 'COMPENSATION',
    TIMESHEET_ADJUSTMENT = 'TIMESHEET_ADJUSTMENT',
    TIMESHEET_RECURRING_ADJUSTMENT = 'TIMESHEET_RECURRING_ADJUSTMENT',
    PUBLIC_HOLIDAY = 'PUBLIC_HOLIDAY',
}

export type Timesheet = {
    id: number;
    employee: Employee;
    startAt: Date;
    //endAt can be null in case of a missing clock out
    endAt?: Date;
    originalStartAt?: Date;
    originalEndAt?: Date;
    breakDuration: number;
    systemCorrection: number;
    status: TimesheetsRequestStatus;
    type: TimesheetType;
    area?: Area;
    comment: string;
    leaveTimesheet?: LeaveTimesheet;
    adjustmentCount?: number;
    adjustmentId?: number;
    recurringAdjustmentId?: number;
    shiftId?: number;
    compensationCount: number;
    leaveCount: number;
    unpaidLeaveCount: number;
    lock?: EmployeePayrollLock;
    workedCount: number;
    paymentCount: number;
    paymentId?: number;
    paymentDueDate?: LocalDate;
    warnings: TimesheetWarning[];
    clockInCreationMethod: TimesheetCreationMethod;
    clockOutMethod?: TimesheetCreationMethod;
    timecardStatus: TimesheetTimecardStatus;
    automaticCorrections: TimesheetAutomaticCorrectionType[];
    updatedBy?: Employee;
    createdBy?: Employee;
    updatedAt?: Date;
    statusUpdatedBy?: Employee;
    statusUpdatedAt?: Date;
    referenceDate: LocalDate;
    timesheetSetting?: TimesheetSetting;
    declineComment?: string;
};

export type DailyTimesheetReport = {
    balance: number;
    previousCarryover: number;
    employee: Employee;
    dayTimesheets: DayTimesheet[];
    totalCompensationCount: number;
    totalFutureCompensationCount: number;
    totalContractCount: number;
    totalAdjustmentCount: number;
    totalDifference: number;
    totalLeaveCount: number;
    totalFutureLeaveCount: number;
    totalFutureUnpaidLeaveCount: number;
    totalUnpaidLeaveCount: number;
    totalPaymentCount: number;
    totalWorkedCount: number;
    totalPublicHolidayCount: number;
    missingCount: number;
    pendingCount: number;
    totalMissingCount: number;
    totalBonusCount: number;
    totalDifferenceCumulated: number;
};

export type TimesheetSearch = {
    limit?: number;
    offset?: number;
    sort?: string;
    sortDirection?: string;
    employeeIds?: number[];
    startDate: LocalDate;
    endDate: LocalDate;
    statuses?: TimesheetsRequestStatus[];
    locationIds?: number[];
    jobIds?: number[];
    departmentIds?: number[];
    managerIds?: number[];
    cycleMonth?: MONTHS;
    onlyEmployeesWithActiveContract?: boolean;
};

export type TimesheetEmployeeMonthSearch = {
    limit?: number;
    offset?: number;
    sort?: string;
    sortDirection?: string;
    employeeId: number;
    month: MONTHS;
    year: number;
};

export type TimesheetPendingSearch = {
    roleIds?: number[];
    locationIds?: number[];
    jobIds?: number[];
    departmentIds?: number[];
    limit?: number;
};

export type EmployeeTimesheetMutation = {
    employeeId: number;
    referenceDate: LocalDate;
    timesheets: TimesheetMutation[];
};

export type TimesheetClockInAreaUpdateMutation = {
    areaId: number;
    employeeId: number;
};

export type DeclineTimesheetMutation = {
    employeeId: number;
    timesheetIds: number[];
    comment?: string;
};

export type CancelTimesheetMutation = {
    employeeId: number;
    timesheetIds: number[];
};

export interface ApproveTimesheetMutation {
    employeeId: number;
    timesheetIds: number[];
}

export type TimesheetMutation = {
    id?: number;
    startTime: Date;
    endTime: Date;
    comment?: string;
    areaId?: number;
    breakDuration?: number;
};

export interface DayTimesheet {
    date: LocalDate;
    timesheets: Timesheet[];
    plannedTimesheets: Timesheet[];
    workedCount: number;
    leaveCount: number;
    unpaidLeaveCount: number;
    contractCount: number;
    difference: number;
    bonusCount: number;
    compensationCount: number;
    futureCompensationCount: number;
    futureUnpaidLeaveCount: number;
    futureLeaveCount: number;
    paymentCount: number;
    adjustmentCount: number;
    publicHolidayCount: number;
    cumulatedBalance: number;
    nonWorkingType: NonWorkingType | undefined;
}

export interface PendingDayTimesheet {
    date: LocalDate;
    employee: Employee;
    timesheets: Timesheet[];
    plannedTimesheets: Timesheet[];
}

export interface ClockInOutCreationMutation {
    employeeId: number;
    dateTime: Date;
    areaId?: number;
    comment?: string;
}

export const TIMESHEET_PAYMENT_STATUS = {
    PENDING: 'PENDING',
    DECLINED: 'DECLINED',
    CANCELLED: 'CANCELLED',
    PAID: 'PAID',
};

export type MonthTimesheet = {
    month: MONTHS;
    totalCompensationCount: number;
    totalFutureCompensationCount: number;
    totalContractCount: number;
    totalDifference: number;
    totalLeaveCount: number;
    totalUnpaidLeaveCount: number;
    totalFutureLeaveCount: number;
    totalPublicHolidayCount: number;
    totalPaymentCount: number;
    totalWorkedCount: number;
    totalMissingCount: number;
    missingCount: number;
    pendingCount: number;
    totalAdjustmentCount: number;
    totalBonusCount: number;
    locked: boolean;
    lastEmployeePayrollLock?: EmployeePayrollLock;
};

export type TimesheetAutomaticCorrectionType = 'BREAK' | 'START_DATE' | 'END_DATE';

export type TimesheetTimecardStatus = 'MANUAL_UPDATE' | 'MISSING_CLOCK_OUT' | 'CLOCK_IN_OUT' | 'AUTOMATIC_CORRECTION';

export type TimesheetClockInRule = 'OUTSIDE_WORKING_HOURS' | 'SUNDAY_AND_PUBLIC_HOLIDAYS' | 'CLOCK_IN_ALLOWED';

export type MonthlyTimesheetReport = {
    forecastedBalance: number;
    balance: number;
    previousCarryover: number;
    employee: Employee;
    monthTimesheets?: MonthTimesheet[];
    totalCompensationCount: number;
    totalForecastedCompensationCount: number;
    totalContractCount: number;
    totalAdjustmentCount: number;
    totalForecastedAdjustmentCount: number;
    totalDifference: number;
    totalForecastedDifference: number;
    totalLeaveCount: number;
    totalForecastedLeaveCount: number;
    totalPublicHolidayCount: number;
    totalUnpaidLeaveCount: number;
    totalForecastedUnpaidLeaveCount: number;
    totalPaymentCount: number;
    totalForecastedPaymentCount: number;
    totalBonusCount: number;
    totalForecastedBonusCount: number;
    totalWorkedCount: number;
    totalMissingCount: number;
    missingCount: number;
    pendingCount: number;
    // this attr is not from the api
    exportMonthEndDate: Date;
};

export enum TimesheetAction {
    CREATE = 'CREATE',
    EDIT = 'EDIT',
    MISSING_TIMESHEET = 'MISSING_TIMESHEET',
    SHIFT_TIMESHEET = 'SHIFT_TIMESHEET',
    AUTO_FILL_TIMESHEET = 'AUTO_FILL_TIMESHEET',
}

export enum TimesheetRequestDisplayStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    DECLINED = 'DECLINED',
    CANCELLED = 'CANCELLED',
    MISSING = 'MISSING',
    FUTURE_MISSING = 'FUTURE_MISSING',
    SHIFT_TIMESHEET = 'SHIFT_TIMESHEET',
    AUTO_FILLED = 'AUTO_FILLED',
    LOCKED = 'LOCKED',
    NON_WORKING_DAY = 'NON_WORKING_DAY',
}

export type TimesheetCycle = {
    cycleStartDate: LocalDate;
    cycleEndDate: LocalDate;
    startMonth: MONTHS;
    year: number;
};
