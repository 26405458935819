import { Objective } from '@/domain/objective/Objective.model';
import { useTheme } from '@mui/material';
import { Text, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectiveItemPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackObjectivePDF';

type FeedbackObjectivesActionsPDFProps = {
    objectives: Objective[];
};

export const FeedbackObjectivesActionsPDF: FC<FeedbackObjectivesActionsPDFProps> = ({ objectives }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <View style={{ gap: theme.spacing(1) }}>
            <Text
                style={{
                    fontSize: 16,
                    paddingLeft: theme.spacing(2),
                    fontWeight: 'bold',
                }}
            >
                {t('reviews.write_feedback.create_objective_action_title')}
            </Text>

            {objectives?.map(objective => <ObjectiveItemPDF objective={objective} key={objective?.id} />)}
        </View>
    );
};
