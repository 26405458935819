import { StackProps, Typography } from '@mui/material';
import { FC } from 'react';
import { FeedbackSectionFormType } from '@/page/review/employee-review-feedback-form/FeedbackFormPage.schema';
import { getLabelTranslation } from '@/utils/language.util';

type FeedbackSectionProps = {
    sectionItem: FeedbackSectionFormType;
} & StackProps;

export const FeedbackSection: FC<FeedbackSectionProps> = ({ sectionItem }) => {
    return (
        <Typography
            variant='h1'
            sx={{
                px: 1,
            }}
        >
            {getLabelTranslation(sectionItem.title) ?? ''}
        </Typography>
    );
};
