import { FeedbackQuestionFormType } from '@/page/review/employee-review-feedback-form/FeedbackFormPage.schema';
import { FeedbackQuestion } from '@/page/review/employee-review-feedback-form/FeedbackQuestion';
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Paper, Stack, Typography, useTheme } from '@mui/material';
import { FC, Ref, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SquareLock02Icon, ViewIcon, ViewOffIcon } from 'hugeicons-react';
import { getLabelTranslation } from '@/utils/language.util';
import { RichTextTypography } from '@/components/rich-text-typography/RichTextTypography';

export type SummaryPrivateQuestion = {
    feedbackSummaryQuestionForm: FeedbackQuestionFormType;
    onUpdate: (item: FeedbackQuestionFormType) => void;
    innerRef: Ref<HTMLDivElement>;
    disabled: boolean;
    error: FieldError | undefined;
    expandAll: boolean;
};

export const FeedbackSummaryPrivateQuestion: FC<SummaryPrivateQuestion> = ({ feedbackSummaryQuestionForm, onUpdate, innerRef, disabled, error, expandAll }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
        setExpanded(expandAll);
    }, [expandAll]);

    return (
        <Stack
            component={Paper}
            ref={innerRef}
            sx={{
                border: error ? `1px solid ${theme.palette.error.main}` : 'none',
            }}
        >
            <Accordion
                expanded={expanded}
                disableGutters
                defaultExpanded
                sx={{
                    backgroundColor: theme.palette.warning.light,
                }}
            >
                <AccordionSummary onClick={() => setExpanded(!expanded)}>
                    <Stack justifyContent='space-between' direction={'row'} flex={1} gap={1}>
                        <Stack alignItems='center' direction={'row'} gap={1}>
                            <SquareLock02Icon color={theme.palette.warning.dark} />
                            <Stack>
                                <Typography variant='body1bold'>{t('reviews.review_summary.private_manager_assessment')}</Typography>
                                <Typography variant='body2'>{t('reviews.review_summary.private_manager_assessment_description')}</Typography>
                            </Stack>
                        </Stack>

                        <IconButton disabled>
                            {expanded ? <ViewOffIcon color={theme.palette.warning.dark} /> : <ViewIcon color={theme.palette.warning.dark} />}
                        </IconButton>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 0 }}>
                    <Stack gap={0.5}>
                        <Typography variant='body2bold' sx={{ whiteSpace: 'pre-wrap' }}>
                            {getLabelTranslation(feedbackSummaryQuestionForm.title)} {feedbackSummaryQuestionForm.required && '*'}
                        </Typography>

                        {feedbackSummaryQuestionForm.instruction && (
                            <RichTextTypography variant='body2'>{getLabelTranslation(feedbackSummaryQuestionForm.instruction)}</RichTextTypography>
                        )}

                        <FeedbackQuestion
                            sx={{
                                backgroundColor: theme.palette.warning.light,
                            }}
                            questionItem={feedbackSummaryQuestionForm}
                            onUpdate={item => {
                                // Todo: improve typing and use never to avoid inferring the type
                                if (item.type !== 'SKILL') {
                                    onUpdate(item);
                                }
                            }}
                            disabled={disabled}
                            // have to cast the error because the type of the error in the output of fieldState error is not the right one
                            error={
                                error as {
                                    comment?: FieldError;
                                    score?: FieldError;
                                }
                            }
                            innerRef={innerRef}
                            isSummary={true}
                        />
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Stack>
    );
};
