import { FC } from 'react';
import { FeedbackFormType } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { useTheme } from '@mui/material';
import { RatingFormType } from '@/page/review/employee-review-feedback-form/FeedbackFormPage.schema';
import { Text, View } from '@react-pdf/renderer';
import { FeedbackScorePDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackScorePDF';

type FeedbackFromPreparationPDFProps = {
    feedbackForm: FeedbackFormType;
    rating: RatingFormType | undefined;
};

export const FeedbackFromPreparationPDF: FC<FeedbackFromPreparationPDFProps> = ({ feedbackForm, rating }) => {
    const theme = useTheme();

    if (!feedbackForm) {
        return <></>;
    }

    return (
        <View
            style={{
                display: 'flex',
                gap: theme.spacing(0.5),
            }}
        >
            <View
                style={{
                    gap: theme.spacing(1),
                    alignItems: 'center',
                    flexDirection: 'row',
                    display: 'flex',
                }}
            >
                <Text
                    style={{
                        fontWeight: 'bold',
                        maxWidth: '15vw',
                    }}
                >
                    {feedbackForm.reviewer.displayName}
                </Text>
                {feedbackForm.score && <FeedbackScorePDF score={feedbackForm.score} rating={rating} />}
            </View>
            <Text>{feedbackForm.comment}</Text>
        </View>
    );
};
