import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { getCurrentAppVersion } from '@/config/config';
import { microsoftLogout, signOut } from '@/domain/authentication/Authentication.service';
import { hasAccessRogerHelpCenterPolicy } from '@/domain/permission/Permission.service';
import { useGetApiInfo } from '@/hooks/api-info/ApiInfo.hook';
import { employeeSignedOut } from '@/stores/reducers/currentEmployeeSlice';
import { useAppDispatch, useCurrentEmployee, useCurrentLoginMethod, useCurrentPolicies } from '@/stores/store';
import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router';
import { LoginMethodType } from '@/domain/realm/Realm.model';

export const UserAccess: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const currentEmployee = useCurrentEmployee();
    const policies = useCurrentPolicies();
    const loginMethod = useCurrentLoginMethod();
    const { data: apiInfo } = useGetApiInfo();

    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const userMenuOpen = Boolean(anchorEl);

    const handleMenuClose = () => {
        setAnchorEl(undefined);
    };

    const canSeeHelpCenter = hasAccessRogerHelpCenterPolicy(policies);

    const handleLogout = async () => {
        await signOut(currentEmployee.id);
        if (loginMethod?.type === LoginMethodType.MICROSOFT) {
            microsoftLogout();
        }
        queryClient.clear();
        dispatch(employeeSignedOut(undefined));
        navigate('/login');
        handleMenuClose();
    };

    return (
        <>
            <Stack
                onClick={event => {
                    setAnchorEl(event.currentTarget);
                }}
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                flexWrap='nowrap'
            >
                <EmployeeAvatar size='large' employeeAvatar={currentEmployee} />
            </Stack>

            <Menu
                anchorEl={anchorEl}
                id='user-menu'
                disableAutoFocusItem
                open={userMenuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 50, horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                MenuListProps={{
                    subheader: currentEmployee ? (
                        <Box pt={2} px={2}>
                            <Typography variant='body2bold'>{currentEmployee.displayName}</Typography>
                        </Box>
                    ) : undefined,
                }}
            >
                <MenuItem component={RouterLink} to='/profile/personal-info'>
                    {t('header.my_profile')}
                </MenuItem>
                <MenuItem component={RouterLink} to='/user'>
                    {t('header.my_settings')}
                </MenuItem>
                {canSeeHelpCenter && (
                    <MenuItem component='a' href='https://support.rogerhr.ch' target='_blank' rel='noreferrer'>
                        {t('header.help')}
                    </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>{t('header.logout')}</MenuItem>
                <MenuItem disabled dense>
                    {`${t('header.fe_version')}: ${getCurrentAppVersion()}`}
                </MenuItem>
                {apiInfo?.version && (
                    <MenuItem disabled dense>
                        {`${t('header.be_version')}: ${apiInfo.version}`}
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};
