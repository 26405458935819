import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { FooterActions, FooterActionsProps } from '@/page/layout/Footer';
import {
    isEmployeeReviewCancelled,
    isEmployeeReviewClosed,
    isEmployeeReviewCompleted,
    isEmployeeReviewEmployeeApproved,
    isEmployeeReviewInputNeeded,
    isEmployeeReviewStarted,
    isEmployeeReviewSubmitted,
    submitEmployeeReviewDiscussion,
} from '@/domain/employee-review/EmployeeReview.service';
import { SummaryFeedbackForm } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import {
    EmployeeReviewFeedbackSummaryMutation,
    EmployeeReviewManagerSummary,
} from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { Objective } from '@/domain/objective/Objective.model';
import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import {
    CombinedFeedbackItemsType,
    FeedbackStepItemsType,
    feedbackStepSchema,
    FeedbackSummarySkillFormType,
    MergedFeedbackItemsType,
    PrivateFeedbackStepItemsType,
    privateFeedbackStepSchema,
    QuestionFeedbackFormType,
    SkillQuestionFeedbackFormType,
    SummaryFeedbackItemFormType,
    SummaryFeedbackSkillsFormType,
} from './SummaryFeedbackForm.schema';
import {
    createEmployeeReviewFeedbacksManagerSummary,
    createEmployeeReviewFeedbacksManagerSummaryItem,
} from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.service';
import { handleError } from '@/utils/api.util';
import { PrivateSummaryFeedbackFormStep } from '@/page/review/employee-review-summary-feedback-form/PrivateSummaryFeedbackForm';
import { useStepper } from '@/components/stepper-form/StepperForm.util';
import { mapSummaryFeedbackItem } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.util';
import { FormProvider, Resolver, SubmitErrorHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useScrollToFirstError } from '@/hooks/ErrorScroll.hook';
import { OnboardingProfileStepFormValues } from '@/page/people/on-boarding-form/EmployeeInformationsForm/EmployeeInformationForm';
import { isReviewClosed } from '@/domain/review/Review.service';
import { Button, ButtonProps, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { CompleteReviewTemplateForm } from '@/page/setting/review/template/ReviewTemplateFormPage.schema';
import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { StepperButtonConfigs, StepperForm } from '@/components/stepper-form/StepperForm';
import { showSnackbar } from '@/utils/snackbar.util';
import { SummaryFeedbackFormHeader } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackFormHeader';
import { EmployeeReviewAction } from '@/domain/employee-review-action/EmployeeReviewAction.model';

type LoadingButtonProps = FooterActionsProps['actions'][number];

type ManagerSummaryFeedbackFormProps = {
    employeeReview: EmployeeReview;
    managerSummary: EmployeeReviewManagerSummary;
    objectives: Objective[];
    refetchObjectives: () => void;
    employeeReviewId: number;
    actions: EmployeeReviewAction[];
    refetchActions: () => void;
};
export const ManagerSummaryFeedbackForm: FC<ManagerSummaryFeedbackFormProps> = ({
    employeeReview,
    managerSummary,
    objectives,
    refetchObjectives,
    employeeReviewId,
    actions,
    refetchActions,
}) => {
    const [isCorrectionSummaryMode, setIsCorrectionSummaryMode] = useState(false);
    const [expandAll, setExpandAll] = useState(true);
    const employeeId = employeeReview?.employee.id;
    const readingFeedbackByManager = employeeReview?.status === EmployeeReviewStatus.INPUT_NEEDED;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const scrollRef = useRef<HTMLDivElement>();

    const [confirmSubmitFeedbackDialogOpen, setConfirmSubmitFeedbackDialogOpen] = useState(false);

    const isFormDisabled = () => {
        return (
            (!employeeReview ||
                isEmployeeReviewInputNeeded(employeeReview.status) ||
                isEmployeeReviewClosed(employeeReview.status) ||
                isEmployeeReviewCancelled(employeeReview.status) ||
                isEmployeeReviewEmployeeApproved(employeeReview.status) ||
                isEmployeeReviewSubmitted(employeeReview.status)) &&
            !isCorrectionSummaryMode
        );
    };

    const onSaveItem = async (feedbackFormItem: SummaryFeedbackItemFormType, employeeReviewFeedbackSummarySkillForm?: FeedbackSummarySkillFormType) => {
        let employeeReviewFeedbackSummaryMutation: EmployeeReviewFeedbackSummaryMutation | undefined = undefined;

        switch (feedbackFormItem.type) {
            case 'SECTION':
                return;
            case 'SKILL':
                employeeReviewFeedbackSummaryMutation = {
                    reviewItemId: feedbackFormItem.id,
                    score: feedbackFormItem.score,
                    comment: feedbackFormItem.comment,
                    skillId: feedbackFormItem.skillId,
                    objectiveId: undefined,
                };
                break;
            case 'PRIVATE_EMPLOYEE_QUESTION':
            case 'PRIVATE_MANAGER_QUESTION':
            case 'QUESTION':
                employeeReviewFeedbackSummaryMutation = {
                    reviewItemId: feedbackFormItem.id,
                    score: feedbackFormItem.score,
                    comment: feedbackFormItem.comment,
                    skillId: undefined,
                    objectiveId: undefined,
                };
                break;
            case 'SKILLS':
                employeeReviewFeedbackSummaryMutation = {
                    reviewItemId: feedbackFormItem.id,
                    score: employeeReviewFeedbackSummarySkillForm?.score,
                    comment: employeeReviewFeedbackSummarySkillForm?.comment,
                    skillId: employeeReviewFeedbackSummarySkillForm?.skill.id,
                    objectiveId: undefined,
                };
                break;
        }

        if (!employeeReviewFeedbackSummaryMutation) {
            return;
        }

        try {
            await createEmployeeReviewFeedbacksManagerSummaryItem(employeeReviewId, employeeReviewFeedbackSummaryMutation);
        } catch (error) {
            handleError(error);
        }
    };
    const getSteps = () => {
        const hasPrivateQuestions = managerSummary.items.some(item => ['PRIVATE_EMPLOYEE_QUESTION', 'PRIVATE_MANAGER_QUESTION'].includes(item.type));

        const summaryStep = {
            stepComponent: (
                <SummaryFeedbackForm
                    isFormDisabled={isFormDisabled()}
                    employeeId={employeeId}
                    scrollRef={scrollRef}
                    objectives={objectives || []}
                    refetchObjectives={refetchObjectives}
                    onSaveItem={onSaveItem}
                    expandAll={expandAll}
                    managerSummary={managerSummary}
                    employeeReview={employeeReview}
                    actions={actions}
                    refetchActions={refetchActions}
                />
            ),
            stepName: 'EMPLOYEE_REVIEW_SUMMARY_FEEDBACK',
            stepSchema: feedbackStepSchema,
            stepTranslationKey: 'reviews.review_summary.discussion',
        };

        const privateStep = {
            stepComponent: (
                <PrivateSummaryFeedbackFormStep isFormDisabled={isFormDisabled()} scrollRef={scrollRef} onSaveItem={onSaveItem} expandAll={expandAll} />
            ),
            stepName: 'EMPLOYEE_REVIEW_PRIVATE_SUMMARY_FEEDBACK',
            stepSchema: privateFeedbackStepSchema,
            stepTranslationKey: 'reviews.review_summary.private_feedback',
        };

        return hasPrivateQuestions ? [summaryStep, privateStep] : [summaryStep];
    };

    const steps = getSteps();

    const stepperValues = useStepper({
        steps,
    });

    const { onNextStep, currentStep } = stepperValues;
    const stepName = steps?.find(step => step?.stepName === currentStep?.stepName)?.stepName;

    const isPrivateQuestion = (item: SummaryFeedbackItemFormType): boolean =>
        item.type === 'PRIVATE_EMPLOYEE_QUESTION' || item.type === 'PRIVATE_MANAGER_QUESTION';

    const getDefaultValues = (managerSummary: EmployeeReviewManagerSummary): Partial<MergedFeedbackItemsType> => {
        const sortedFeedbackItems = (employeeReview.review?.items ?? [])
            .map(item => mapSummaryFeedbackItem(item, managerSummary))
            // An item might not have a feedback, so we filter out the undefined items
            .filter(item => !!item)
            .sort((a, b) => (a?.order && b?.order ? a.order - b.order : 0));

        const privateQuestions = sortedFeedbackItems.filter(val => isPrivateQuestion(val)) as PrivateFeedbackStepItemsType[];
        const nonPrivateQuestions = sortedFeedbackItems.filter(val => !isPrivateQuestion(val)) as FeedbackStepItemsType[];

        return {
            summaryFeedbackItems: nonPrivateQuestions,
            summaryPrivateFeedbackItems: privateQuestions,
        };
    };

    const getResolverForStepName = (
        step_name: 'EMPLOYEE_REVIEW_SUMMARY_FEEDBACK' | 'EMPLOYEE_REVIEW_PRIVATE_SUMMARY_FEEDBACK',
    ): Resolver<CombinedFeedbackItemsType> => {
        switch (step_name) {
            case 'EMPLOYEE_REVIEW_SUMMARY_FEEDBACK':
                return yupResolver<CombinedFeedbackItemsType>(feedbackStepSchema);
            case 'EMPLOYEE_REVIEW_PRIVATE_SUMMARY_FEEDBACK':
                return yupResolver<CombinedFeedbackItemsType>(privateFeedbackStepSchema);
        }
    };

    const resolver: Resolver<MergedFeedbackItemsType> = getResolverForStepName(
        stepName as 'EMPLOYEE_REVIEW_SUMMARY_FEEDBACK' | 'EMPLOYEE_REVIEW_PRIVATE_SUMMARY_FEEDBACK',
    ) as unknown as Resolver<MergedFeedbackItemsType>;

    const defaultValues = getDefaultValues(managerSummary);
    const formMethods = useForm<MergedFeedbackItemsType>({
        resolver: resolver,
        defaultValues: defaultValues,
    });

    const { handleSubmit, watch, formState } = formMethods;
    const { resetFocus } = useScrollToFirstError(formState);

    const onError: SubmitErrorHandler<OnboardingProfileStepFormValues> = errors => {
        console.error(errors);
        resetFocus();
    };

    const getPrimaryActionLabel = (employeeReviewStatus: EmployeeReviewStatus | undefined): string => {
        const validationLabel = t('reviews.review_summary.validation');
        const submitDiscussionLabel = t('reviews.review_summary.submit_discussion');
        const passToValidationLabel = t('reviews.review_summary.pass_to_validation');
        const closeLabel = t('reviews.review_summary.close');

        let validateLabel = '';
        if (employeeReview?.review?.includeValidationStep) {
            validateLabel = passToValidationLabel;
        } else if (employeeReview?.review?.type === 'ONE_SHOT') {
            validateLabel = submitDiscussionLabel;
        } else {
            validateLabel = validationLabel;
        }

        switch (employeeReviewStatus) {
            case EmployeeReviewStatus.DISCUSSION_STARTED:
                return validateLabel;
            case EmployeeReviewStatus.DISCUSSION_SUBMITTED:
                if (isCorrectionSummaryMode) {
                    return validateLabel;
                }
                return closeLabel;
            case EmployeeReviewStatus.EMPLOYEE_APPROVED:
            case EmployeeReviewStatus.INPUT_NEEDED:
            case EmployeeReviewStatus.CANCELLED:
            default:
                return closeLabel;
        }
    };

    const getPrimaryAction = (): ButtonProps => {
        const primaryActionLabel = getPrimaryActionLabel(employeeReview?.status);
        if (!employeeReview) {
            return {
                onClick: () => {
                    navigate(-1);
                },
                children: primaryActionLabel,
                variant: 'contained',
                name: 'close',
            };
        }

        if (employeeReview?.review && (isReviewClosed(employeeReview?.review) || readingFeedbackByManager) && !isCorrectionSummaryMode) {
            return {
                onClick: () => {
                    navigate(-1);
                },
                children: primaryActionLabel,
                variant: 'contained',
                name: 'close',
            };
        }
        if (isEmployeeReviewStarted(employeeReview.status) || isCorrectionSummaryMode) {
            return {
                type: 'submit',
                form: 'employee-review-summary-feedback-form',
                children: primaryActionLabel,
                variant: 'contained',
                name: 'submit',
            };
        } else {
            return {
                onClick: () => navigate('/reviews/team-reviews'),
                children: primaryActionLabel,
                variant: 'contained',
                name: 'close',
            };
        }
    };

    const correctSummaryButton: LoadingButtonProps = {
        name: 'correct',
        onClick: () => {
            setIsCorrectionSummaryMode(true);
        },
        children: t('reviews.review_summary.correct_summary'),
        variant: 'text',
    };

    const secondaryAction: LoadingButtonProps = {
        onClick: () => {
            navigate('/reviews/team-reviews');
        },
        children: t('reviews.write_feedback.continue_later'),
        variant: 'outlined',
        name: 'continue-later',
    };

    const getFooterActions = (): LoadingButtonProps[] => {
        const isCorrectionButtonVisible = !!employeeReview?.status && isEmployeeReviewSubmitted(employeeReview?.status) && !isCorrectionSummaryMode;
        const actions = employeeReview && isEmployeeReviewCompleted(employeeReview) ? [] : [secondaryAction];
        return isCorrectionButtonVisible ? [correctSummaryButton, ...actions] : actions;
    };

    // todo: use error scroll hook
    const setFocusOnFirstError: SubmitErrorHandler<CompleteReviewTemplateForm> = errors => {
        console.error(errors);
        const el = document.querySelector('.Mui-error, [data-error]');
        (el?.parentElement ?? el)?.scrollIntoView();
    };

    const validateAndNextStep = () => {
        return formMethods.handleSubmit(onNextStep, setFocusOnFirstError)();
    };

    const openConfirmSubmitFeedbackDialog = () => {
        setConfirmSubmitFeedbackDialogOpen(true);
    };

    const mapFeedbackItemToMutation = (
        item: QuestionFeedbackFormType | SkillQuestionFeedbackFormType | SummaryFeedbackSkillsFormType,
    ): EmployeeReviewFeedbackSummaryMutation[] => {
        switch (item.type) {
            case 'SKILL':
                return [
                    {
                        reviewItemId: item.id,
                        score: item.score,
                        comment: item.comment,
                        skillId: item.skillId,
                        objectiveId: undefined,
                    },
                ];
            case 'QUESTION':
                return [
                    {
                        reviewItemId: item.id,
                        score: item.score,
                        comment: item.comment,
                        skillId: undefined,
                        objectiveId: undefined,
                    },
                ];
            case 'SKILLS':
                return item.skills.map(skill => ({
                    reviewItemId: item.id,
                    score: skill.score,
                    comment: skill.comment,
                    skillId: skill.skill.id,
                    objectiveId: undefined,
                }));
            default:
                return [];
        }
    };

    const mapPrivateFeedbackItemToMutation = (item: QuestionFeedbackFormType): EmployeeReviewFeedbackSummaryMutation => ({
        reviewItemId: item.id,
        score: item.score,
        comment: item.comment,
        skillId: undefined,
        objectiveId: undefined,
    });

    const submitDiscussion = async (employeeReviewSummaryFeedbackFormType: MergedFeedbackItemsType) => {
        const employeeReviewFeedbackSummaryMutations = employeeReviewSummaryFeedbackFormType.summaryFeedbackItems
            .filter(
                (item): item is QuestionFeedbackFormType | SkillQuestionFeedbackFormType | SummaryFeedbackSkillsFormType =>
                    item?.type === 'QUESTION' || item?.type === 'SKILL' || item?.type === 'SKILLS',
            )
            .flatMap(item => mapFeedbackItemToMutation(item)); // Utilisation correcte pour déplier les mutations de SKILLS

        const privateEmployeeReviewFeedbackMutations = employeeReviewSummaryFeedbackFormType.summaryPrivateFeedbackItems
            .filter((item): item is QuestionFeedbackFormType => item?.type === 'PRIVATE_EMPLOYEE_QUESTION' || item?.type === 'PRIVATE_MANAGER_QUESTION')
            .map(mapPrivateFeedbackItemToMutation);

        const mutation = [...employeeReviewFeedbackSummaryMutations, ...privateEmployeeReviewFeedbackMutations];

        try {
            await createEmployeeReviewFeedbacksManagerSummary(employeeReviewId, mutation);
            await submitEmployeeReviewDiscussion(employeeReviewId);
            showSnackbar(t('reviews.review_summary.messages.discussion_submitted'), 'success');
            setConfirmSubmitFeedbackDialogOpen(false);
            navigate('/reviews/team-reviews');
        } catch (error) {
            handleError(error);
        }
    };

    const customButtonConfigs: StepperButtonConfigs = {
        nextButton: {
            onClick: validateAndNextStep,
        },
        saveButton: {
            ...getPrimaryAction(),
        },
    };

    return (
        <FormProvider {...formMethods}>
            <Stack gap={2} flex={1}>
                <Stack
                    direction='column'
                    gap={2}
                    sx={{
                        width: {
                            lg: '1000px',
                            xs: '100%',
                        },
                    }}
                    alignSelf={'center'}
                >
                    <SummaryFeedbackFormHeader
                        managerSummary={managerSummary}
                        objectives={objectives}
                        summaryFeedbackItems={watch('summaryFeedbackItems')}
                        expandAll={expandAll}
                        handleExpandAllChange={() => setExpandAll(prevExpandAll => !prevExpandAll)}
                        employeeReview={employeeReview}
                        actions={actions}
                    />
                </Stack>

                <StepperForm
                    steps={steps}
                    stepperValues={stepperValues}
                    onNextStep={validateAndNextStep}
                    onSave={formMethods.handleSubmit(openConfirmSubmitFeedbackDialog, onError)}
                    customButtonConfigs={customButtonConfigs}
                >
                    <FooterActions actions={getFooterActions()} />
                </StepperForm>

                {confirmSubmitFeedbackDialogOpen && (
                    <DialogWrapper
                        header={t('reviews.summary.confirm_submit_feedback_dialog_title')}
                        open={true}
                        onClose={() => setConfirmSubmitFeedbackDialogOpen(false)}
                    >
                        <DialogContent>
                            <Typography variant='body2'>{t('reviews.summary.confirm_submit_feedback_dialog_subtitle_review_summary')}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button fullWidth onClick={handleSubmit(submitDiscussion, console.error)}>
                                {t('reviews.summary.confirm_submit_feedback_dialog_confirm')}
                            </Button>
                        </DialogActions>
                    </DialogWrapper>
                )}
            </Stack>
        </FormProvider>
    );
};
