import { FC } from 'react';
import { FeedbackFormType } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { StarsIcons } from '@/components/stars-icons/StarsIcons';
import { FeedbackSkillFormType, LevelFormType } from '@/page/review/employee-review-feedback-form/FeedbackFormPage.schema';

type SkillFeedbackFromPreparationProps = {
    feedbackForm: FeedbackFormType;
    skillAndCategory: FeedbackSkillFormType;
};

export const SkillFeedbackFromPreparation: FC<SkillFeedbackFromPreparationProps> = ({ feedbackForm, skillAndCategory }) => {
    const theme = useTheme();

    if (!feedbackForm || (!feedbackForm.score && !feedbackForm.comment)) {
        return <></>;
    }

    const getScoreLevelName = (score: number | undefined, levels: LevelFormType[] | undefined): string => {
        if (!levels || !score) {
            return '';
        }
        const level = levels?.find(level => level.score === score);
        return level?.name ?? '';
    };

    return (
        <Stack gap={0.25}>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
                <EmployeeAvatar employeeAvatar={feedbackForm.reviewer} />
                {!!feedbackForm.score && (
                    <Stack p={0.5} px={1} width={240} borderColor={theme.palette.text.primary} border={'1px solid'} borderRadius={1}>
                        {/*todo: fix ellipsis not working properly*/}
                        <Tooltip title={feedbackForm.comment} sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            <Stack direction={'row'} alignItems={'center'} gap={1}>
                                <StarsIcons starsNumber={feedbackForm.score} htmlColor={theme.palette.text.primary} />
                                <Typography color={theme.palette.text.primary} variant='body1' flexWrap={'nowrap'} whiteSpace={'nowrap'}>
                                    {getScoreLevelName(feedbackForm.score, skillAndCategory.skill.levels)}
                                </Typography>
                            </Stack>
                        </Tooltip>
                    </Stack>
                )}
            </Stack>

            <Typography variant={'body2'}> {feedbackForm.comment}</Typography>
        </Stack>
    );
};
