import { EmployeeAvatarWithDetails } from '@/components/employee-avatar/EmployeeAvatarWithDetails';
import { getCurrentPrincipalEmployment } from '@/domain/employment/Employment.service';
import { useGetEmployee } from '@/hooks/employee/Employee.hook';
import { useEmployeeProfileId } from '@/page/employee-profile/useEmployeeProfileId';
import { useCurrentEmployee } from '@/stores/store';
import { getLabelTranslation } from '@/utils/language.util';
import { Paper, Stack, StackProps, Typography } from '@mui/material';
import { FC } from 'react';

export const EmployeeProfileCardMobile: FC<StackProps> = props => {
    const currentEmployee = useCurrentEmployee();
    const employeeId = useEmployeeProfileId();

    const isCurrentEmployee = employeeId === currentEmployee.id;

    const { data: employee } = useGetEmployee(employeeId, { options: { enabled: !isCurrentEmployee } });

    const employeeToDisplay = isCurrentEmployee ? currentEmployee : employee;

    const employment = employee ? getCurrentPrincipalEmployment(employee) : undefined;
    return (
        <Stack component={Paper} direction={'row'} p={2} {...props}>
            <EmployeeAvatarWithDetails employee={employeeToDisplay} avatarProps={{ size: 'large' }}>
                <Typography variant='body2'>{getLabelTranslation(employment?.job?.name)}</Typography>
            </EmployeeAvatarWithDetails>
        </Stack>
    );
};
