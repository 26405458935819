import { FC, useState } from 'react';
import { FeedbackSummarySkillFormType } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { SkillState } from '@/page/review/employee-review-feedback-form/FeedbackSkill';
import { Stack, Typography } from '@mui/material';
import { SkillFeedbackFromPreparation } from '@/page/review/employee-review-summary-feedback-form/SkillFeedbackFromPreparation';
import { SkillReviewDialog } from '@/page/review/employee-review-skill-review-dialog/SkillReviewDialog';
import { useTranslation } from 'react-i18next';
import { DisabledSkillButton, NotReviewedSkillButton, ReviewedSkillButton, SummaryMeetingSkillButton } from '@/domain-ui/skill/SkillButton';
import { assignLocalId } from '@/utils/object.util';
import { FeedbackSkillFormType } from '@/page/review/employee-review-feedback-form/FeedbackFormPage.schema';
import { hasAtLeastOneFeedback } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.service';

type FeedbackSummarySkillProps = {
    skillState: SkillState;
    feedbackSkillItem: FeedbackSummarySkillFormType;
    handleSkillChanged: (skillAndCategory: FeedbackSkillFormType) => void;
    disabled: boolean;
};

export const FeedbackSummarySkill: FC<FeedbackSummarySkillProps> = ({ skillState, feedbackSkillItem, handleSkillChanged, disabled }) => {
    const { t } = useTranslation();
    const [activeSkill, setActiveSkill] = useState<FeedbackSummarySkillFormType>();
    const handleSkillClicked = (skillAndCategory: FeedbackSummarySkillFormType) => {
        setActiveSkill(skillAndCategory);
    };

    const onClose = () => {
        setActiveSkill(undefined);
    };

    const renderSkillItem = (skillState: SkillState, feedbackSkillItem: FeedbackSummarySkillFormType) => {
        switch (skillState) {
            case 'reviewed':
                return (
                    <ReviewedSkillButton
                        skillScore={feedbackSkillItem.score}
                        skillName={feedbackSkillItem.skill.name ?? ''}
                        handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)}
                    />
                );
            case 'not_reviewed':
                return (
                    <NotReviewedSkillButton skillName={feedbackSkillItem.skill.name ?? ''} handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)} />
                );
            case 'summary_meeting':
                return (
                    <SummaryMeetingSkillButton
                        skillName={feedbackSkillItem.skill.name ?? ''}
                        handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)}
                    />
                );
            case 'disabled':
                return <DisabledSkillButton skillName={feedbackSkillItem.skill.name ?? ''} handleSkillClicked={() => handleSkillClicked(feedbackSkillItem)} />;
        }
    };

    return (
        <>
            {renderSkillItem(skillState, feedbackSkillItem)}
            {activeSkill && (
                <SkillReviewDialog skillAndCategory={activeSkill} onConfirm={handleSkillChanged} onClose={onClose} disabled={disabled}>
                    {hasAtLeastOneFeedback(feedbackSkillItem) && (
                        <Stack>
                            <Stack gap={1} pb={2}>
                                <Typography variant='body1bold'>{t('reviews.summary.preparation_subtitle')}</Typography>

                                <Stack gap={1} pb={1}>
                                    {feedbackSkillItem.selfFeedbackQuestion && (
                                        <SkillFeedbackFromPreparation feedbackForm={feedbackSkillItem.selfFeedbackQuestion} skillAndCategory={activeSkill} />
                                    )}

                                    {feedbackSkillItem.managerFeedbackQuestion?.map(managerFeedbackSkillQuestion => (
                                        <SkillFeedbackFromPreparation
                                            key={assignLocalId(managerFeedbackSkillQuestion).localId}
                                            feedbackForm={managerFeedbackSkillQuestion}
                                            skillAndCategory={activeSkill}
                                        />
                                    ))}
                                    {feedbackSkillItem.upwardFeedbackQuestion?.map(upwardFeedbackSkillQuestion => (
                                        <SkillFeedbackFromPreparation
                                            key={assignLocalId(upwardFeedbackSkillQuestion).localId}
                                            feedbackForm={upwardFeedbackSkillQuestion}
                                            skillAndCategory={activeSkill}
                                        />
                                    ))}
                                    {feedbackSkillItem.peerFeedbackQuestion?.map(peerFeedbackSkillQuestion => (
                                        <SkillFeedbackFromPreparation
                                            key={assignLocalId(peerFeedbackSkillQuestion).localId}
                                            feedbackForm={peerFeedbackSkillQuestion}
                                            skillAndCategory={activeSkill}
                                        />
                                    ))}
                                </Stack>
                            </Stack>
                            <Typography variant='body1bold'>{t('reviews.summary.summary_subtitle')}</Typography>
                        </Stack>
                    )}
                </SkillReviewDialog>
            )}
        </>
    );
};
