import { StateHandler } from '@/components/state-handler/StateHandler';
import { Employee } from '@/domain/employee/Employee.model';
import { TimesheetMode } from '@/domain/timesheet-setting/TimesheetSetting.model';
import { Timesheet, TimesheetAction, TimesheetType } from '@/domain/timesheet/Timesheet.model';
import { useGetEmployeeTimesheets } from '@/hooks/timesheet/Timesheet.hook';

import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TimesheetSimplifiedModeDialogContent } from '@/page/timesheet/timesheet-dialog/TimesheetSimplifiedModeDialogContent';
import { getDialogTitleKey, getOriginalTimesheets, hasAtLeastOneTimesheetType } from '@/page/timesheet/timesheet-dialog/TimesheetDialog.util';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { TimesheetNormalModeDialogContent } from '@/page/timesheet/timesheet-dialog/TimesheetNormalModeDialogContent';

type TimesheetDialogProps = {
    mode: TimesheetAction;
    onSave: () => void;
    employee: Employee;
    defaultReferenceDate: LocalDate;
    missingEntriesDates?: LocalDate[]; //used when we want to complete multiple missing timesheets at once
} & DialogWrapperProps;

export const TimesheetDialog: FC<TimesheetDialogProps> = props => {
    const { employee, defaultReferenceDate } = props;
    const timesheetSetting = employee.currentWorkingPattern?.timesheetSetting;
    const [referenceDate, setReferenceDate] = useState<LocalDate>(defaultReferenceDate);

    const { t } = useTranslation();

    const {
        data: dailyTimesheetReports = [],
        isLoading,
        isError,
        error,
    } = useGetEmployeeTimesheets({
        employeeIds: [employee.id],
        startDate: referenceDate,
        endDate: referenceDate,
    });

    const timesheets = getOriginalTimesheets(dailyTimesheetReports);
    const hasExistingTimesheetsInCurrentDate = hasAtLeastOneTimesheetType(timesheets);
    const dialogTitle = t(getDialogTitleKey(props.mode, hasExistingTimesheetsInCurrentDate));
    const isNormalMode = timesheetSetting?.timesheetMode === TimesheetMode.NORMAL;

    //In case there is already timesheets, we should just show the ones that are not missing
    const defaultTimesheets = hasExistingTimesheetsInCurrentDate ? timesheets.filter(isTimesheet) : timesheets;
    // TODO: refactor to make a better split between the two modes
    return (
        <DialogWrapper open={props.open} onClose={props.onClose} header={dialogTitle}>
            <StateHandler isLoading={isLoading} isError={isError} error={error}>
                {isNormalMode ? (
                    <TimesheetNormalModeDialogContent
                        {...props}
                        referenceDate={referenceDate}
                        defaultTimesheets={defaultTimesheets}
                        onReferenceDateChange={setReferenceDate}
                    />
                ) : (
                    <TimesheetSimplifiedModeDialogContent
                        {...props}
                        referenceDate={referenceDate}
                        defaultTimesheets={defaultTimesheets}
                        onReferenceDateChange={setReferenceDate}
                    />
                )}
            </StateHandler>
        </DialogWrapper>
    );
};

const wantedTimesheetTypes = [TimesheetType.SHIFT_TIMESHEET, TimesheetType.TIMESHEET, TimesheetType.AUTOFILL];

const isTimesheet = (timesheet: Timesheet): timesheet is Timesheet => {
    return wantedTimesheetTypes.includes(timesheet.type);
};
