import { EmployeePolicy } from '@/domain/employee/Employee.model';
import { RealmFeature } from '@/domain/realm/Realm.model';
import {
    AnalyticsUpIcon,
    BankIcon as PayrollIcon,
    Calendar03Icon as LeavesIcon,
    CheckmarkBadge02Icon,
    Clock01Icon as TimesheetsIcon,
    DashboardSquare02Icon as PlanningIcon,
    DocumentValidationIcon,
    File01Icon as DocumentIcon,
    Home03Icon as HomeIcon,
    Layout3ColumnIcon as ReportIcon,
    Settings02Icon,
    StarIcon as ReviewsIcon,
    Target01Icon as ObjectivesIcon,
    UserIcon as ProfileIcon,
    UserMultiple02Icon as EmployeesIcon,
} from 'hugeicons-react';

import {
    canAccessOtherEmployeeReviews,
    canConfigureCompanySettings,
    canManageOtherEmployeeObjectives,
    canManageOtherEmployeeTimesheets,
    canManagePayroll,
    canSeeOtherEmployeeLeaves,
    canSeeSurveyResults,
    canViewCompanyDocuments,
    canViewEmployeesDirectory,
    canViewOtherTimesheetInsightBalance,
    canViewReports,
    canViewReviews,
    canViewShifts,
} from '@/domain/permission/Permission.service';
import { getDashboardPages } from '@/page/dashboard/DashboardPages';
import { getDocumentPages } from '@/page/document/DocumentPages';
import { useGetProfilePages } from '@/page/employee-profile/EmployeeProfilePages.hook';
import { getEmployeeSkillPages } from '@/page/employee-skill/EmployeeSkillPages';
import { getHomePages, homePage } from '@/page/home/pending/HomePages';
import { getLeavePages } from '@/page/leave/LeavePages';
import { getObjectivesPages } from '@/page/objective/objectives-page/ObjectivePages';
import { getPayrollPages } from '@/page/payroll/PayrollPages';
import { getPeoplePages } from '@/page/people/PeoplesPages';
import { getPlanningPages } from '@/page/planning/PlanningPages';
import { getReportPages } from '@/page/report/ReportPages';
import { getReviewPages } from '@/page/review/ReviewPages';
import { getSettingsPages } from '@/page/setting/CompanySettingsPages';
import { getSurveyPages } from '@/page/survey/SurveyPages';
import { getTimesheetPages } from '@/page/timesheet/TimesheetPages';
import { useCurrentEmployee, useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { Location } from 'react-router';

export type Page = {
    path: string;
    labelKey: string;
    condition?: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => boolean;
};

export type PagesGroupConfig = {
    pages: Page[];
    icon: JSX.Element;
    labelKey: string;
    canSee: boolean;
};

export const myProfileSidebarLabelKey = 'sidebar.my_profile';
export const managePeopleSidebarLabelKey = 'sidebar.manage_people';

// TODO : make all function to get pages as a hook https://rogerhr.atlassian.net/browse/RP-5963
export const useGetPagesGroupsConfig = (): PagesGroupConfig[] => {
    const realm = useCurrentRealm();
    const realmFeatures = realm.realmFeatures;
    const profilePages = useGetProfilePages();

    const employeeId = useCurrentEmployee().id;
    const policies = useCurrentPolicies();

    return [
        {
            pages: getHomePages(),
            icon: <HomeIcon />,
            labelKey: homePage.labelKey,
            canSee: true,
        },
        {
            pages: profilePages,
            icon: <ProfileIcon />,
            labelKey: myProfileSidebarLabelKey,
            canSee: true,
        },
        {
            pages: getPeoplePages(employeeId, realmFeatures, policies),
            icon: <EmployeesIcon />,
            labelKey: managePeopleSidebarLabelKey,
            canSee: canViewEmployeesDirectory(policies, employeeId),
        },
        {
            pages: getLeavePages(employeeId, realmFeatures, policies),
            icon: <LeavesIcon />,
            labelKey: 'sidebar.manage_leaves',
            canSee: canSeeOtherEmployeeLeaves(realmFeatures, policies, employeeId),
        },
        {
            pages: getPlanningPages(),
            // PlanningIcon size seams to be wrong, so we use size 23
            icon: <PlanningIcon size={23} />,
            labelKey: 'sidebar.planning',
            canSee: canViewShifts(realmFeatures, policies),
        },
        {
            pages: getTimesheetPages(employeeId, realmFeatures, policies),
            icon: <TimesheetsIcon />,
            labelKey: 'sidebar.timesheets',
            canSee:
                canManageOtherEmployeeTimesheets(realmFeatures, policies, employeeId) ||
                canViewOtherTimesheetInsightBalance(realmFeatures, policies, employeeId),
        },
        {
            pages: getDocumentPages(),
            icon: <DocumentIcon />,
            labelKey: 'sidebar.company_documents',
            canSee: canViewCompanyDocuments(realmFeatures, policies),
        },
        {
            pages: getSurveyPages(realmFeatures, policies),
            icon: <DocumentValidationIcon />,
            labelKey: 'sidebar.surveys',
            canSee: canSeeSurveyResults(realmFeatures, policies),
        },
        {
            pages: getReviewPages(realmFeatures, policies),
            icon: <ReviewsIcon />,
            labelKey: 'sidebar.reviews',
            canSee: canAccessOtherEmployeeReviews(realmFeatures, policies, employeeId) || canViewReviews(realmFeatures, policies),
        },
        {
            pages: getObjectivesPages(),
            icon: <ObjectivesIcon />,
            labelKey: 'sidebar.objectives',
            canSee: canManageOtherEmployeeObjectives(realmFeatures, policies, employeeId),
        },
        {
            pages: getEmployeeSkillPages(employeeId, realmFeatures, policies),
            icon: <CheckmarkBadge02Icon />,
            labelKey: 'sidebar.employee_skills',
            canSee: canAccessOtherEmployeeReviews(realmFeatures, policies, employeeId),
        },
        {
            pages: getPayrollPages(realmFeatures, policies, employeeId),
            icon: <PayrollIcon />,
            labelKey: 'sidebar.payroll',
            canSee: canManagePayroll(realmFeatures, policies, employeeId),
        },
        {
            pages: getReportPages(),
            icon: <ReportIcon />,
            labelKey: 'sidebar.reports',
            canSee: canViewReports(realmFeatures, policies),
        },
        {
            pages: getDashboardPages(),
            icon: <AnalyticsUpIcon />,
            labelKey: 'sidebar.dashboard',
            // We don't want to display the dashboard for the moment, the page is available by the direct link
            canSee: false,
            // canSee: canViewDashboard(policies, employeeId),
        },
        {
            pages: getSettingsPages(),
            icon: <Settings02Icon />,
            labelKey: 'sidebar.company_settings',
            canSee: canConfigureCompanySettings(policies),
        },
    ].filter(({ canSee }) => canSee);
};

// Add the beta page urls here
const BETA_PAGE_URLS = ['/dashboard', '/people/employment-mass-edit'];
export const isBetaPage = (location: Location): boolean => {
    return BETA_PAGE_URLS.includes(location.pathname);
};
